<template>
    <div>

        <!-- SEARCH BAR -->
        <!-- <div class="px-2 my-2">
            <div class="input-group search">
                <span class="input-group-text"><i class="bx bx-search"></i></span>
                <input type="text" class="form-control" placeholder="What are you looking for...">
            </div>
        </div> -->
        <!-- END SEARCHBAR -->

        <!-- BANNER -->
        <!-- <div>
            <b-carousel id="carousel-1" :interval="4000" indicators >
                <b-carousel-slide v-for="item in banners" :img-src="item.banner_picture"></b-carousel-slide> 
            </b-carousel>
        </div> -->
        <swiper :options="swiperOptionsBanner" class="w-100">
            <swiper-slide v-for="item in banners" :key="index" class="rounded swiper-shadow">
                <img style="height:200px;object-fit: cover;" class="rounded w-100" :src="item.banner_picture" />
            </swiper-slide> 
        </swiper>
        <!-- END BANNER -->

        <div class="text-white text-center mt-5 mb-5 px-2">
            <h2 class="text-white font-weight-bolder">Mongrela Associated Vet</h2>
            <p class="text-justify">
                Join us in enhancing the lives of our little friends with the support of our community of vets and clinics. Your participation is crucial in providing our furry companions with an improved and healthier life. Be a part of our mission to make a positive impact on the well-being of our beloved pets.        
            </p>
        </div>

        <div class=" px-2">
            <router-link to="/vet/list">
                <div class="text-white d-flex align-items-center p-1 mt-1" style="gap:10px;background-color: #1B628C;border: 2px solid #FFAD32;border-radius: 20px;">
                    <div class="h4 text-white mb-0">Vet List</div>
                    <i class="bx bx-chevron-right ml-auto" style="font-size: 20px;"></i>
                </div>
            </router-link>
        </div>

        <!-- LEARN MORE -->
        <div class="px-2 mt-3 mb-2">
            <swiper :options="swiperOptions" class="w-100">
                <swiper-slide v-for="(data, index) in ads" :key="index" class="rounded swiper-shadow">
                    <img style="height:200px;object-fit: cover;" class="rounded w-100" :src="data.ads_picture" />
                </swiper-slide>
                <div slot="button-next" class="swiper-button-next d-flex"><i
                        class="m-auto bx bx-chevron-right text-dark m-auto" style="font-size:28px"></i></div>
                <div slot="button-prev" class="swiper-button-prev d-flex"><i
                        class="m-auto bx bx-chevron-left text-dark m-auto" style="font-size:28px"></i></div>
            </swiper>
        </div>
        <!-- END LEARN MORE -->

        <!-- <div class="text-white mt-5 px-2">
            <h2 class="text-white font-weight-bolder">We Need Your Help</h2>

            <div class="row my-2" style="gap:10px 0">
                <div class="col-12">
                    <img style="border-radius: 10px;" class="w-100"
                        src="https://d1csarkz8obe9u.cloudfront.net/posterpreviews/pet-shop-cover-template-design-7fbcea6de5ed45af0716f61106314548_screen.jpg?ts=1622085870" />
                </div>
                <div class="col-6" style="padding-right: .5em;">
                    <img style="border-radius: 10px;" class="w-100"
                        src="https://d1csarkz8obe9u.cloudfront.net/posterpreviews/pet-shop-cover-template-design-7fbcea6de5ed45af0716f61106314548_screen.jpg?ts=1622085870" />
                </div>
                <div class="col-6" style="padding-left: .5em;">
                    <img style="border-radius: 10px;" class="w-100"
                        src="https://d1csarkz8obe9u.cloudfront.net/posterpreviews/pet-shop-cover-template-design-7fbcea6de5ed45af0716f61106314548_screen.jpg?ts=1622085870" />
                </div>
            </div>

            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus vehicula porttitor magna a porttitor. Fusce nibh lectus, faucibus ut imperdiet nec,
            </p>
        </div> -->
        
    </div>
</template>

<script>

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import {BCarousel, BCarouselSlide} from 'bootstrap-vue'

import store from '@/store'

export default {
    components: {
        Swiper, SwiperSlide,
        BCarousel, BCarouselSlide
    },
    computed: {
        banners(){
            return store.state.banners.BANNERS || []
        },
        ads(){
            return store.state.banners.ADS || []
        },
    },
    mounted(){
        store.dispatch('banners/GetBanners')
        store.dispatch('banners/GetAds')
    },
    data() {
        return {
            learn_more_banner: [
                { url: 'https://d1csarkz8obe9u.cloudfront.net/posterpreviews/pet-shop-cover-template-design-7fbcea6de5ed45af0716f61106314548_screen.jpg?ts=1622085870' },
                { url: 'https://d1csarkz8obe9u.cloudfront.net/posterpreviews/pet-shop-banner-design-template-4c29604da3e3601ec0a5d464e2cbeb4b_screen.jpg?ts=1621925071' }
            ],
            swiperOptions: {
                slidesPerView: 1,
                spaceBetween: 10,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                autoplay: {
                    delay: 3000,
                },
            },
            swiperOptionsBanner: {
                slidesPerView: 1,
                spaceBetween: 10, 
                autoplay: {
                    delay: 3000,
                },
            },
        }
    }
}

</script>

<style> .input-group.search span {
    border-right: unset;
    border-color: #FFAD32;
    border-radius: 10px 0 0 10px;
    font-size: 24px;
    color: #FFAD32;
    background-color: transparent;
}

.input-group.search input {
    margin-left: 0;
    padding-left: 0;
    border-left: unset;
    border-radius: 0 10px 10px 0;
    border-color: #FFAD32;
    height: 40px;
    color: white;
    background-color: transparent !important;
}

.swiper-button-prev,
.swiper-button-next {
    --swiper-theme-color: transparent;
    background: #ffffff;
    box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.2);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 0 !important;
}

.swiper-button-prev::after,
.swiper-button-next::after {
    content: unset !important;
}
</style> 